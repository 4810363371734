import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 430,
            sm: 800,
            md: 1280,
            lg: 1920,
        },
        // scrollBarWidth: 24,
    },
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
            customProperties: true,
        },
		themes: {    
            light: {
                primary: "#CC0200",
                secondary: "#FFFFFF",
                accent: "#BD0200",
                error: "#DA4C4C",
                success: "#219653",
                warning: "#FDC000",
                darkgrey: "#E1E1E1",
                lightgrey: "#F0F0F0",
                background: "#FFFFFF",
                grey: "#DBDBDC",
                medical: "#1eba4d",
            },
        },
    },
});
