<template>
    <v-banner app class="primary full-width fixed foreground-highest elevation-0 pa-0 ma-0 bottom-border" height="70">
        <div class="d-flex align-center justify-center">
            <div class="d-flex align-center">
                <div class="text-h7 white--text font-weight-bold pr-6">App Update Available</div>
                <v-btn @click='$emit("refreshApp")' depressed class="animate__animated animate__pulse animate__infinite white--text accent secondary--text animated"><v-icon class="mr-2">icons8-download-from-cloud</v-icon>Update Now</v-btn>
            </div>
            <v-spacer />
            <v-btn @click="appupdate = false" icon><v-icon class="white--text">icons8-close</v-icon></v-btn>
        </div>
    </v-banner>
</template>
<script>
export default {
    methods: {
        refresh() {
            window.location.reload();
        },
    },
};
</script>
