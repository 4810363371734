import { mapActions } from "vuex";
import { getDownloadURL, ref } from "firebase/storage";
// import jwt_decode from "jwt-decode";
const mixin = {
    data: () => ({
        // API_URL: process.env.VUE_APP_MOFSERVER,
    }),
    methods: {
        ...mapActions({
            ACT_alertBox: "ACT_alertBox",
        }),
        // * CHANGE ROUTE FUNCTION
        MIX_go(path) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err);
            });
        },
        MIX_alertBox(payload) {
            this.ACT_alertBox(payload)
        },
        // * CHANGE ROUTE FUNCTION
        MIX_goExternal(path) {
            window.open(path, "_blank").focus();
        },
        // FORMAT DATE TIME NOW
        MIX_formatDateTimeNow: function (formatOut) {
            return this.$dayjs().format(formatOut);
        },
        // FORMAT DATE TIME
        MIX_formatDateTime: function (date, formatIn, formatOut) {
            if (date !== "" && date !== null && date !== undefined && date != 0) {
                switch (formatOut) {
                    case 'toISOString':
                        return this.$dayjs(date, formatIn).toISOString();
                    case 'toJSON':
                        return this.$dayjs(date, formatIn).toJSON();
                    case 'toObject':
                        return this.$dayjs(date, formatIn).toObject();
                    case 'toArray':
                        return this.$dayjs(date, formatIn).toArray();
                    case 'unix':
                        return this.$dayjs(date, formatIn).unix()
                    case 'valueOf':
                        return this.$dayjs(date, formatIn).valueOf()
                    default:
                        return this.$dayjs(date, formatIn).format(formatOut)
                }
            } else {
                return "";
            }
        },
        // ADD HOUR MINUTES SECONDS TO DATE TIME
        MIX_addTimeAndFormatDateTime: function(data, formatIn, formatOut, hours, minutes, seconds) {
            if (data !== "" && data !== null && data !== undefined && data != 0) {
                let date = this.$dayjs(data, formatIn);
                date = date.add(hours, 'hour');
                date = date.add(minutes, 'minute');
                date = date.add(seconds, 'second');
                switch (formatOut) {
                    case 'toISOString':
                        return this.$dayjs(date, formatIn).toISOString();
                    case 'toJSON':
                        return this.$dayjs(date, formatIn).toJSON();
                    case 'toObject':
                        return this.$dayjs(date, formatIn).toObject();
                    case 'toArray':
                        return this.$dayjs(date, formatIn).toArray();
                    case 'unix':
                        return this.$dayjs(date, formatIn).unix()
                    case 'valueOf':
                        return this.$dayjs(date, formatIn).valueOf()
                    default:
                        return this.$dayjs(date, formatIn).format(formatOut)
                }
            } else {
                return "";
            }
        },
        MIX_googlePlacesConversion (placeResultData) {
			placeResultData = JSON.parse(placeResultData);
			// console.log('placeResultData', JSON.stringify(placeResultData, null, 2));
			let result = {}
            result.name = placeResultData.name;
            let premise = placeResultData.address_components.filter((component) => component.types.includes('premise'));
            let street_number = placeResultData.address_components.filter((component) => component.types.includes('street_number'));
            let route = placeResultData.address_components.filter((component) => component.types.includes('route'));
            let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_1'));
            let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_2'));
            let locality = placeResultData.address_components.filter((component) => component.types.includes('locality'));
            let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes('administrative_area_level_2'));
            let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
            let postal_code = placeResultData.address_components.filter((component) => component.types.includes('postal_code'));
            let postal_town = placeResultData.address_components.filter((component) => component.types.includes('postal_town'));
            let formatted_phone_number = placeResultData.formatted_phone_number;

            var addressLine1 = '';
            var addressLine2 = '';
            // Address Line 1
            if (JSON.stringify(premise) !== '[]') {
                addressLine1 = premise[0]['long_name'];
            }
            if (JSON.stringify(street_number) !== '[]') {
                addressLine1 = addressLine1 + ' ' + street_number[0]['long_name'];
            }
            if (JSON.stringify(route) !== '[]') {
                addressLine1 = addressLine1 + ' ' + route[0]['long_name'];
            }
            if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== '') {
                result.addressLine1 = addressLine1.trim();
            } else {
                result.addressLine1 = placeResultData.formatted_address.split(',')[0];
            }
            // Address Line 2
            if (JSON.stringify(sublocality_level_1) !== '[]') {
                addressLine2 = sublocality_level_1[0]['long_name'];
            }
            if (JSON.stringify(sublocality_level_2) !== '[]') {
                addressLine2 = addressLine2 + ' ' + sublocality_level_2[0]['long_name'];
            }
            if (JSON.stringify(locality) !== '[]') {
                addressLine2 = addressLine2 + ' ' + locality[0]['long_name'];
            }
            if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== '') {
                result.addressLine2 = addressLine2.trim();
            }
            // Address Line 3
            result.AddressLine3 = '';
            // Check for Postal Town
            if (JSON.stringify(postal_town) !== '[]') {
                result.town = postal_town[0]['long_name'];
            }
            // Check for County
            if (JSON.stringify(administrative_area_level_2) !== '[]') {
                result.county = administrative_area_level_2[0]['long_name'];
            }
            // Check for Country
            if (JSON.stringify(country) !== '[]') {
                result.country = country[0]['long_name'];
            }
            // Check for postcode
            if (JSON.stringify(postal_code) !== '[]') {
                result.postcode = postal_code[0]['long_name'];
            }
            // Add LAttitude and Longitude
			// console.log('lat = ', placeResultData.geometry);
            result.lat = placeResultData.geometry.location.lat;
            result.lng = placeResultData.geometry.location.lng;
            // Check for Phone Number
            if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== '') {
                result.elephone = formatted_phone_number.replace(/\s/g, '');
            }
            // Add Website
            result.website = placeResultData.website;
			return result;
		},
        // // * FORMAT DATE TIME
        // MIX_formatDateTime: function (date, formatIn, formatOut) {
        // 	if (date !== "" && date !== null && date !== undefined && date != 0) {
        // 		return moment(date, formatIn).format(formatOut);
        // 	} else {
        // 		return moment().format(formatOut);
        // 	}
        // },
        // MIX_addFormatDateTime: function(data, formatIn, formatOut, timeValue, timeUnit) {
        // 	if (data !== "" && data !== null && data !== undefined && data != 0) {
        // 		return moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
        // 	} else {
        // 		return moment().add(timeValue, timeUnit).format(formatOut);
        // 	}
        // },
        // MIX_fromNow: function (date, formatIn, ago) {
        // 	return moment(date, formatIn).fromNow(ago);
        // },
        // generates a random alphanumeric string
        MIX_generateId() {
            let generatedId = "";
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
            for (let i = 0; i < 20; i++) {
                generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return generatedId;
        },
        // * GET IMAGE URL
        MIX_getImgUrl(image) {
            var images = require.context('@/assets/', false, /\.(jpg|png)$/)
            return images('./' + image)
        },
        MIX_getFirebaseImgUrl(folder, imageId, imageType) {
            let t = this
            const storageRef = ref(t.$firebase.publicStorage, folder + '%2F' + imageId + '.' + imageType);
            getDownloadURL(storageRef).then((url) => {
                if(url !== undefined){
                    console.log("download URL: "+url);
                    return url
                } else{
                    return ""
                }
                });
        },
        //JWT Decoding
        // MIX_tokenToUser(token){
        //     return jwt_decode(token);
        // },
        MIX_isLoggedIn(){
            //check if there is a firebase user
            if(this.GET_currentUserProfile != null){
                return true;
            //check if there is a local storage user
            }else if(localStorage.userToken){
                return true;
            }else{
                return false;
            }
        },
        // * GET STORE DETAILS
        MIX_getStoreDetails(storeId){
            let store = this.$sampledata.stores.find((x)=>x.entityid === storeId) 
            if(store !== undefined){
                return store
            } else{
                return ""
            }
        },
    },
};

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
