<template>
  <div :class="primaryclass" style="min-height: 100px !important">
    <main class="pa-3">
      <v-container class="lightgrey rounded-of">
         <!--<pre>{{ datadown }}</pre>-->
        <v-row>
          <v-col cols="6" xs="6" sm="8" class="primary" style="border-top-left-radius: 15px !important;">
            <div class="title text-md-title white--text text-left font-weight-bold">
              {{ datadown.carparkName }}
            </div>
            <div class="text-subtitle-1 white--text text-md-title text-left">
              Note: {{ datadown.carparkNotes || 'N/A' }}
            </div>
            <div class="subtitle-1 white--text text-left">
              {{datadown.carparkType}}
            </div>
          </v-col>

          <v-col cols="6" xs="6" sm="4" class="d-flex justify-end primary" style="border-top-right-radius: 15px !important;">
            <v-btn :href="'https://www.google.com/maps/search/?api=1&query='+datadown.carparkName+ ','+datadown.carparkAddress1+ ',' +datadown.carparkPostcode"
                   class="font-weight-bold primary white--text rounded-of removetexttransform" style="height: 4em;" depressed>
              <v-icon class="white--text" large>icons8-place-marker</v-icon>
                  <div class="title full-width text-center pl-2" v-if="$vuetify.breakpoint.width >= 1440">{{ $t("openinmaps") }}</div>
            </v-btn>
          </v-col>

          <v-col cols="6" xs="6" sm="8">
            <div class="text-subtitle-1 text-md-title text-left">
              {{ datadown.carparkPaymentMethod }}
            </div>
            <div class="text-subtitle-1 text-md-title font-weight-bold text-left">
              Address:
            </div>
            <div class="text-subtitle-1 text-md-title text-left">
              {{ datadown.carparkAddress1 }}
            </div>
            <div class="text-subtitle-1 text-md-title text-left">
              {{ datadown.carparkPostcode }}
            </div>

          </v-col>

          <v-col cols="6" xs="6" sm="4">
            <div class="subtitle-1 text-right">
              <b>{{datadown.carparkSpaces}}</b> {{ $t("spaces") }}
            </div>
            <div class="subtitle-1 text-right">
              <b>{{datadown.carparkSpacesDisabled}}</b> {{ $t("disabledspaces") }}
            </div>
            <div class="subtitle-1 text-right">
              <b>{{datadown.carparkChargingSpaces}}</b> {{ $t("chargingspaces") }}
            </div>
            <!--<div class="mt-4 d-flex align-center justify-end">-->
            <!--  <v-btn :href="'https://www.google.com/maps/search/?api=1&query='+datadown.carparkName+ ','+datadown.carparkAddress1+ ',' +datadown.carparkPostcode"-->
            <!--         class="font-weight-bold primary white&#45;&#45;text rounded-of removetexttransform" style="height: 4em;" depressed>-->
            <!--    <v-icon class="white&#45;&#45;text" large>icons8-place-marker</v-icon>-->
            <!--    <div class="title full-width text-center pl-2">{{ $t("openinmaps") }}</div>-->
            <!--  </v-btn>-->
            <!--</div>-->
          </v-col>

        </v-row>

      </v-container>
    </main>
  </div>
</template>

<script>

export default {
  name: "ParkingCard",
  components: {},
  props: {
    headerclass: {
      type: String,
      default: "",
    },
    footerclass: {
      type: String,
      default: "",
    },
    primaryclass: {
      type: String,
      default: "white rounded-of",
    },
    toptitle: {
      type: String,
      default: "TopTitle",
    },
    datadown: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>