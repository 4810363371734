<template>
    <div>
            <div class="d-flex justify-center align-center pt-4">
                <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
            </div>
            <v-row class="px-10">
                <v-col cols="6">
                    <div class="title font-weight-black primary--text">Defibrillators</div>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                    <AddDefib class=""/>
                </v-col>
                <v-col cols="3" class="lightgrey rounded-of-left full-height pb-0">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" class="d-flex align-center justify-start">
                            <div class="removetexttransform" style="font-size: 14px; min-width:8em;">Available Times</div>
                            <v-select :menu-props="{ top: false, offsetY: true }" v-model="defib.times" :items=times background-color="#DBDBDC" dense solo class="rounded-of fontsizesmall"/>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" class="d-flex align-center justify-start" style="margin-top:-1em;">
                            <div class="removetexttransform" style="font-size: 14px; min-width:7em;">Access Type</div>
                            <v-select :menu-props="{ top: false, offsetY: true }" v-model="defib.access" :items=access background-color="#DBDBDC" dense solo class="rounded-of fontsizesmall" />
                        </v-col>
                        <v-col cols="12" class="d-flex align-center pb-4" style="margin-top:-1.5em;">
                            <div class="removetexttransform" style="font-size: 14px;">Only show defibrillators that are available now</div>
                            <v-checkbox v-model="defib.availablenow" label="" value="TRUE"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <MapFilter class="" height="452"/>
                </v-col>
                <v-col cols="9" class="d-flex lightgrey justify-center rounded-of-right adminmap">
                    <DefibMap class="rounded-of-right full-width"></DefibMap>
                </v-col>
            </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "AdminDefibView",
    data: () => ({
        defib: {
            times: "24/7",
            access: "Public",
            availablenow: "NO",
        },
        times: ["24/7"],
        access: ["Public", "Private"]
    }),
    components: {
    DefibMap: () => import("@/components/DefibMap.vue"),
    MapFilter: () => import("@/components/MapFilter.vue"),
    AddDefib: () => import("@/components/AddDefib.vue"),
},
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}
.v-carousel__controls{
    margin-top: 200px !important;
}
.adminmap{
    padding: 0 !important;
}
.fontsizesmall .v-input__control .v-input__slot .v-select__slot .v-select__selections {
    font-size: 14px !important;
  }
</style>
