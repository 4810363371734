<template>
    <v-select
        :label="label"
        :items="items"
        :item-text="itemtext"
        :item-value="itemvalue"
        :return-object="returnobject"
        :error-messages="error"
        v-model="innerValue"
        filled
        rounded
        class="rounded-lg"
        background-color="grey lighten-4"
        hide-details="auto"
        :menu-props="{ top: false, offsetY: true }"
        :attach="true"
        clearable
        @click:clear="clear()">
    </v-select>
</template>

<script>
export default {
    name: 'DropdownSelect',
    props: {
        value: {},
        label: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default: () => [],
        },
        returnobject: {
            type: Boolean,
            default: false,
        },
        itemtext: {
            type: String,
            default: "text",
        },
        itemvalue: {
            type: String,
            default: "value",
        },
        error: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            innerValue: "",
        };
    },
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = "";
            }, 1);
        },
    },    
    watch: {
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>
