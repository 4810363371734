<template>
    <div>
        <div class="d-flex justify-center align-center pt-4">
            <v-img src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
            <v-row class="dense px-10">
                <v-col cols="6">
                    <div class="title font-weight-black primary--text pl-4">Customers</div>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                    <v-btn @click="updateShowFilterDrawer()" class="white rounded-of" depressed>
                        <div class="title pr-4 removetexttransform">Filter stats</div>
                        <v-icon class="primary--text" x-large>icons8-slider</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="pl-6">
                    <v-row class="lightgrey rounded-of pb-5">
                        <v-col cols="6">
                            <div class="title font-weight-black black--text pl-4">Top Customers</div>
                        </v-col>
                        <v-col cols="6">

                        </v-col>
                        <v-col cols="6" v-for="(customer, index) in $sampledata.customers" :key="index" class="">
                            <TopCustomer :datadown="customer"/>
                        </v-col>
                    </v-row>
                </v-col>
               
            </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "AdminCustomersView",
    data: () => ({
        search: "",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        items: [
            { name: "Item 1", v: "value 1" },
            { name: "Item 2", v: "value 2" },
            { name: "Item 3", v: "value 3" },
            { name: "Item 4", v: "value 4" },
        ],
    }),
    components: {
        TopCustomer: () => import("@/components/card/TopCustomer.vue"),
    },
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}
</style>
