<template>
    <v-combobox v-model="innerValue" :items="items" :hide-no-data="!search" :search-input.sync="search" hide-selected label="Business Rates Ref Numbers" multiple
    small-chips solo dense class="rounded-lg ">
    <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Add Ref No: </span>
          <v-chip
            label
            small
          >
             {{ search }}
          </v-chip>
        </v-list-item>
      </template>    
    <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" :input-value="selected" label small>
                <span class="pr-2">
                    {{ item }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                    $delete
                </v-icon>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
export default {
    data: () => ({
        search : '',
        activator: null,
        attach: null,
        items: [],
        innerValue: {},
        store: {
            businessRatesRefNo: ['13423452','13423363452']
        },
    }),
    props:{
        value: {
            type: Array,
            default: null
        },
    },
    methods:{
        filter (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
}
</script>

<style scoped>

</style>