<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain
                class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <v-row>
                <v-col cols="12" xs="12" sm="6">
                    <div class="title font-weight-black primary--text">{{ $t("predictedvotes") }}</div>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                    <div class="title text-end">Yes: {{ votedata.yes }}/{{ votedata.total }} | No: {{ votedata.no }}/{{ votedata.total }} | Maybe: {{ votedata.maybe }}/{{ votedata.total }}</div>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
           <v-row class="lightgrey rounded-of">
               <v-col cols="6" xs="6" sm="6" class="">
                   <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="white"
                                style="padding-left:1em; max-width: 17em;" class="mobilesearch" />
               </v-col>
            <v-col cols="12" xs="12" sm="12" class="pa-6">
                <v-data-table :headers="eventHeaders" mobile-breakpoint="0" :items="stores" :search="search" class="tablestyle" density="comfortable">
                    
                    <!-- <template v-slot:[`header.name`]="{ header }">
                       {{ header.name }} test
                    </template> -->

                    <template v-slot:[`item.name`]="{ item }">
                        <BidMembersStoreCard :item="item"/>
                    </template>

                    <template v-slot:[`item.contactFirstName`]="{ item }">
                        <DesignatedVoterFromID :userId="item.designatedVoterId"/>
                    </template>

                    <template v-slot:[`item.predictedVote`]="{ item }">
                        <PredictedVote :datadown="item"/>
                    </template>

                </v-data-table>
                <!-- <pre>{{ store }}</pre> -->
            </v-col>
           </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, getDownloadURL } from "firebase/storage";
export default {
    name: "AdminUsersView",
    data: () => ({
        votedata:{
            total: 0,
            yes: 0,
            no: 0,
            maybe: 0,},
        loading: false,
        stores: [],
        search: "",
        eventHeaders: [
            { text: "Store name", value: "name" },
            { text: "Designated voter", value: "contactFirstName" },
            { text: "Predicted vote", value: "predictedVote" }
        ],
        items: {
            name: "",
            businessImageID: "",
            contactFirstName: "",
            contactLastName: "",
            businessEmail: "",
        },
    }),
    components: {
        BidMembersStoreCard: () => import("@/components/card/BidMembersStoreCard.vue"),
        PredictedVote: () => import("@/components/card/PredictedVote.vue"),
        DesignatedVoterFromID: () => import("@/components/util/DesignatedVoterFromID.vue"),
    },
    computed:{
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
    },
    methods:{
        async initialise() {
            this.loading = true;
            //let contactresult = await this.REDIS_getAll("contact");
            //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
            let storeresult = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false}");
            this.votedata.total = storeresult.data.total;
            this.stores = storeresult.data.documents;
            this.loading = false;
            //console.log(JSON.stringify(this.stores, null, 2))
            //console.log(this.stores[1].name)
        },
        async getVotes(){
            let totalyes = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Yes}");
            this.votedata.yes = totalyes.data.total;
            let totalno = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{No}");
            this.votedata.no = totalno.data.total;
            let totalmaybe = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Maybe}");
            this.votedata.maybe = totalmaybe.data.total;
        },
        async getImageURL(businessImageID, businessImagetype){
            this.loading = true
            //console.log("getImageURL: "+businessImageID+"."+businessImagetype)
            let t = this
            const storageRef = ref(t.$firebase.publicStorage, "orgImages" + '/' + businessImageID + '.' + businessImagetype);
            let imgURL=""
            await getDownloadURL(storageRef).then((url) => {
                if(url !== undefined){
                    console.log("download URL: "+url);
                    imgURL = url
                    return imgURL
                } else{
                    return null
                }
                });
            this.loading = false
        },
    },
    created(){
        this.initialise();
        this.getVotes();
    }
};
</script>

<style scoped>
.noboxshadow {
    box-shadow: none !important;

}
.paragraphtext{
    line-height: 1.4rem !important;
}

.tablestyle{
    background: none !important;
}

tr{
  height: 80px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
   font-size: 15px !important;
   font-weight: 700 !important;
   color: black !important;
}

</style>
