<template>
    <div class="primary">
        <v-container class="">
            <v-overlay :opacity="0.4" :value="loading" color="black">
                <v-progress-circular indeterminate size="64" color="primary">
                </v-progress-circular>
            </v-overlay>
            <v-row class="" style="min-height: 100vh;">
                <v-col cols="12">
                    <v-img src="@/assets/Big-Heart-Logo.png" class="loginicon" contain />
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="nopadding d-flex">
                    <div class="pb-5 pt-10 white rounded-of-top-xl">
                        <div class="text-center primary--text font-weight-black display-1">{{ $t("login") }}</div>
                        <v-row class="px-1 pt-2 d-flex justify-center" dense>
                            <v-col cols="12">
                                <div class="text-center title">{{ $t("loginmessage1") }}</div>
                            </v-col>
<!--                          Swansea Council Login-->
<!--                            <v-col cols="12">-->
<!--                                <div class="text-center title">{{ $t("loginmessage3") }}</div>-->
<!--                            </v-col>-->
<!--                            <v-col cols="12" xs="12" sm="5" class="px-6 pb-6 pt-3 d-flex align-center">-->
<!--                                <v-btn href="https://ccosauth.b2clogin.com/ccosauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SiUpInV2&client_id=58f56c3e-ca42-4627-80f9-9413e4e64b61&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fswansea-bid-app-demo.web.app%2Fb2c-register&scope=openid&response_type=id_token&prompt=login" x-large depressed block-->
<!--                                    class="primary white&#45;&#45;text rounded-of biggerbutton pr-3">-->

<!--                                    <v-row>-->
<!--                                        <v-col cols="8" class="d-flex align-center justify-center">-->
<!--                                            <div class="text-center white&#45;&#45;text title">{{ $t("login") }}</div>-->
<!--                                        </v-col>-->
<!--                                        <v-col cols="4" class="pa-1 primary darken-1 rounded-of-right">-->
<!--                                            <v-img src="@/assets/Council-Logo-White.png" contain height="90px" />-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                -->
<!--                                </v-btn>-->
<!--                            </v-col>-->
                            <v-col cols="12">
                                <div class=" title text-center primary--text font-weight-black">{{ $t("loginmessage3") }}:</div>
                            </v-col>
                            <v-col cols="12" class="px-6 pb-3">
                                <TextField v-on:submit="login()" label="Email..." background="lightgrey" makedense v-model="form.email" />
                            </v-col>
                            <v-col cols="12" class="px-6 pb-3">
                                <!-- <TextField label="Password..." background="lightgrey" makedense v-model="form.password" type="password" /> -->
                                <PasswordField v-on:submit="login()" background="lightgrey" label="Password"
                                    v-model="form.password" />
                            </v-col>
                            <v-col cols="6" class="pl-6 pb-3">
                                <v-btn to="/" x-large depressed block
                                    class="lightgrey black--text rounded-of subtitle-1">{{ $t("back") }}</v-btn>
                            </v-col>
                            <v-col cols="6" class="pr-6 pb-3">
                                <v-btn @click="login()" x-large depressed block
                                    class="primary white--text rounded-of subtitle-1">{{ $t("login") }}</v-btn>
                            </v-col>
                            <v-col cols="12" class="px-6 pb-3">
                                <div class="text-center title">{{ $t("loginmessage4") }} <a
                                        @click="MIX_go('/register')"><b>{{ $t("signup") }}</b></a></div>
                            </v-col>
                            <v-col cols="12" class="px-6 pb-3 pt-6">
                                <PasswordResetDialog />
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "LoginView",
    data: () => ({
        loading: false,
        form: {
            email: "",
            password: "",
        },
    }),
    components: {
        // FarmCard: () => import("@/components/card/FarmCard.vue"),
        PasswordResetDialog: () => import("@/components/util/PasswordResetDialog.vue"),
    },
    methods: {
        ...mapActions({
            ACT_isAdmin: "ACT_isAdmin",
            ACT_authenticated: "ACT_authenticated",
        }),
        // login() {
        //     this.ACT_authenticated(true);
        //     this.ACT_isAdmin(false);
        //     this.$router.push("/home");
        // },
        initialise() {
            // this.ACT_authenticated(false);
            // this.ACT_isAdmin(false);
            // this.FIREBASE_logout();
        },
        async login() {
            let t = this;
            t.loading = true
            if(t.form.email === ''){
                t.MIX_alertBox({ show: true, message: 'Email Required', color: "error", timeout: "4000" });
                t.loading = false
                return
            }
            if(t.form.email === ''){
                t.MIX_alertBox({ show: true, message: 'Password Required', color: "error", timeout: "4000" });
                t.loading = false
                return
            }

            let firebaseLoginResult = await t.FIREBASE_login(t.form.email, t.form.password);
            if (firebaseLoginResult.success === true) {
                t.authenticating = true;
                setTimeout(async() => {
                    // Redirect to Home after successful login
                    t.$router.push("/home");
                    t.authenticating = false;
                    t.loading = false
                }, 500); //was 3000
            } else {
                // Show Alert Box with Login Error
                t.MIX_alertBox({ show: true, message: firebaseLoginResult.error, color: "error", timeout: "4000" });
                t.loading = false
            }
        },
    },
    created() {
        this.initialise();
    }
};
</script>

<style scoped>
.nopadding {
    padding: 0 !important;
}

.biggerbutton {
    height: 100px !important;
}

.loginicon {
    height: 250px;
}

@media screen and (max-height: 900px) {
    .loginicon {
        height: 100px;
    }
}
</style>