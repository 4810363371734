<template>
    <div class="langselectbutt">
      <v-btn v-if="languageSelected === 'en'" color="primary darken-1" depressed class="langbutt rounded-of title text-left white--text" @click="changeLocale">CY</v-btn>
      <v-btn v-if="languageSelected === 'cy'" color="primary darken-1" depressed class="langbutt rounded-of title text-left white--text" @click="changeLocale">EN</v-btn>
    </div>
</template>

<script>
export default {
    name: 'LanguageSelector',
    data: () => ({
        languageSelected: 'en',
        label: 'CY',
            languages: [
                { title: 'EN', code: 'en' },
                { title: 'CY', code: 'cy' },
            ],
    }),
    watch: {
    languageSelected: function (value) {
      // console.log("LanguageCurrent: " + this.$i18n.locale)
      // console.log("languageSelected: " + value)
      if (this.$i18n.locale != value) {
        //console.log("Language changed to: " + value)
        this.$i18n.locale = value
        this.$dayjs.locale(value)
        localStorage.setItem('language', value)
        location.reload();
      }
    },
  },
  methods:{
    changeLocale(){
      if (this.$i18n.locale == 'en'){
        this.languageSelected = 'cy'
      } else {
        this.languageSelected = 'en'
      }
    }
  },
    created() {
        this.$i18n.locale = localStorage.getItem('language') || 'en'
        this.$dayjs.locale(localStorage.getItem('language') || 'en')
        this.languageSelected = this.$i18n.locale
    },
}
</script>

<style scoped>
.langselectbutt{
  position: absolute !important;
  top: 20px;
  right: 20px;
  max-width: 5em;
}

.langbutt{
  height: 50px !important;
}

@media screen and (max-height: 900px) {
  .langselectbutt{
  position: absolute !important;
  top: 20px;
  right: 20px;
}
}

.v-menu__content{
  background-color: #CC0300 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin-top: -12px !important;
  box-shadow: none !important;
}


.theme--light.v-list{
  background-color: #CC0300 !important;
}

.v-list-item__title{
  color: white !important;
}

</style>