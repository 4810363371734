// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDK_wbaS6NQ1uwSRVKeYTgN-E4_PO2AoH4",
    authDomain: "swansea-bid-app-demo.firebaseapp.com",
    projectId: "swansea-bid-app-demo",
    storageBucket: "swansea-bid-app-demo.appspot.com",
    messagingSenderId: "996488444327",
    appId: "1:996488444327:web:f3b3e1b1edfe96b5af9e82"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const publicStorage = getStorage(app, "gs://swansea-bid-app-demo.appspot.com");


export {
    auth,
    storage,
    publicStorage,
    //analytics
};