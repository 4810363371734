<template>
    <v-snackbar v-model="GET_alertBox.show" class="mb-5" :color="GET_alertBox.color">
        {{ GET_alertBox.message }}
        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="close"> Close </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            GET_alertBox: 'GET_alertBox',
        }),
    },
    methods: {
        close() {
            this.MIX_alertBox({ show: false, message: '', color: '', timeout: '0'});
        },
    }
};
</script>