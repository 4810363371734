<template>
    <div class="d-flex align-center rounded-of" :class="background" :style="`minWidth: 100% !important; minHeight: ${height} !important;`">
        <v-checkbox v-model="innerValue" light :color="color" :class="checkboxclass" class="rounded-of pa-0 ma-0 ml-6" :background-color="background" hide-details="auto" :disabled="disabled"> </v-checkbox>
        <main class="text-subtitle-1 text--secondary"><slot></slot></main>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "primary",
        },
        background: {
            type: String,
            default: "grey lighten-4",
            // default: "green lighten-2",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        checkboxclass: {
            type: String,
            default: "",
        },
        height: {
            type: String,
            default: "46px",
        }
    },
    data() {
        return {
            innerValue: false,
        };
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>