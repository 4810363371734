import { render, staticRenderFns } from "./DateCalendar.vue?vue&type=template&id=487c8e56&scoped=true&"
import script from "./DateCalendar.vue?vue&type=script&lang=js&"
export * from "./DateCalendar.vue?vue&type=script&lang=js&"
import style0 from "./DateCalendar.vue?vue&type=style&index=0&id=487c8e56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487c8e56",
  null
  
)

export default component.exports