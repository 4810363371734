<template>
    <div class="">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-container>
            <v-overlay
          :opacity="0.3"
          :value="loading"
          color="lightgrey"
        >
        <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay>
            <v-row class="dense">
                <v-col cols="12" xs="12" sm="12" class="">
                    <FarmCard>
                        <!-- <template v-slot:header>Title</template> -->
                        <v-row class="px-1" dense>
                            <v-col cols="12">
                                <div class="title text-center pb-5">{{ $t("welcome") }} <b>{{userFirstName}}</b></div>
<!--                                <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="lightgrey" />-->
                            </v-col>
                        </v-row>
                    </FarmCard>
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="" v-if="isSwanseaResident()">
                    <div class="title pl-4">{{ $t("topoffers") }}</div>
                    <carousel height="auto" :paginationPadding=4 :perPage="1" :perPageCustom="[[768, 1], [1024, 2]]" paginationActiveColor="#CC0200">
                        <slide v-for="(offer, index) in offers" :key="index">
                            <OfferCard :datadown="offer" claimbutton>
                                <template v-slot:toptitle>{{offer.offer}}</template>
                                <template v-slot:bottomtitle>{{offer.store}}</template>
                            </OfferCard>
                        </slide>
                    </carousel>
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="favbarhome">
                    <div class="title pl-4">{{ $t("myfavbis") }}</div>
                    <div v-if="favouritestores.length <= 0" class="subtitle-2 grey--text pl-4">{{ $t("nofavs") }}</div>
                    <carousel height="auto" :paginationEnabled=false :perPage="2" :perPageCustom="[[768, 2], [1024, 4]]">
                        <slide v-for="(store, index) in favouritestores" :key="index">
                            <FavouritesCard :datadown="store" collection="business" v-if="store.orgId != ''">
                                <!-- <template v-slot:icon-name>{{store.icon}}</template> -->
                            </FavouritesCard>
                        </slide>
                    </carousel>
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="favbarhome">
                    <div class="title pl-4">{{ $t("myfavevents") }}</div>
                    <div v-if="favouriteevents.length <= 0" class="subtitle-2 grey--text pl-4">{{ $t("nofavs") }}</div>
                    <carousel height="auto" :paginationEnabled=false :perPage="2" :perPageCustom="[[768, 2], [1024, 4]]">
                        <slide v-for="(event, index) in favouriteevents" :key="index">
                            <FavouritesEventCard :datadown="event" collection="event" v-if="event.eventId !== ''">
                                <!-- <template v-slot:icon-name>{{store.icon}}</template> -->
                            </FavouritesEventCard>
                        </slide>
                    </carousel>
                </v-col>
                <!-- User Offers -->
                <v-col cols="12" xs="12" sm="12" class="" v-if="isSwanseaResident()">
                    <div class="title pl-4">{{ $t("myoffers") }}</div>
                    <div v-if="useroffers.length <= 0" class="subtitle-2 grey--text pl-4">{{ $t("nooffers") }}</div>
                    <carousel height="auto" :paginationPadding=4 :perPage="1" :perPageCustom="[[768, 1], [1024, 2]]" paginationActiveColor="#CC0200">
                        <slide v-for="(offer, index) in availableOffers" :key="index">
                            <UserOfferCard :datadown="offer" v-on:usedOffer="initialise()">
                            </UserOfferCard>
                        </slide>
                    </carousel>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "HomeView",
    data: () => ({
        loading: false,
        userEntityId: "",
        stores: [],
        offers: [],
        useroffers: [],
        availableOffers: [],
        favouritestores: [],
        favouriteevents: [],
        userAuthenticated: false,
        search: "",
        userFirstName: "",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_showFilterDrawer: 'GET_showFilterDrawer',
            GET_authenticated: 'GET_authenticated',
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
        computedSearch(){
            var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
            if(this.search.length > 3 && onlyLetters.test(this.search)){
                return this.search.trim() + "*"
            }else{
                return ""
            }
        }
    },
    components: {
        FarmCard: () => import("@/components/card/FarmCard.vue"),
        OfferCard: () => import("@/components/card/OfferCard.vue"),
        FavouritesCard: () => import("@/components/card/FavouritesCard.vue"),
        FavouritesEventCard: () => import("@/components/card/FavouritesEventCard.vue"),
        UserOfferCard: () => import("@/components/card/UserOfferCard.vue"),
    },
    methods: {
        async initialise(){
            this.loading = true;
            this.getUser();
            await this.getOrgs();
            await this.getOffers();
            if(this.GET_currentUserProfile != null){
                await this.getUserOffers(this.GET_currentUserProfile.entityId);
                await this.getFavouriteStores(this.GET_currentUserProfile.entityId);
                await this.getFavouriteEvents(this.GET_currentUserProfile.entityId);
            }else if(localStorage.userToken){
               const userId = this.MIX_tokenToUser(localStorage.userToken).oid.replace(/-/g, "");
            //    console.log(userId)
                await this.getUserOffers(userId);
                await this.getFavouriteStores(userId);
                await this.getFavouriteEvents(userId);
            }
            this.loading = false; 
        },
        async getOrgs(){
            return new Promise((resolve, reject) => {
                this.REDIS_searchFor("org", "", "", "", "", `${this.computedSearch} @deleted:{false}`)
                .then((result) => {
                    this.stores = result.data.documents;
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async getOffers(){
            return new Promise((resolve, reject) => {
                this.REDIS_searchFor("offer", "", "", "", "", `${this.computedSearch} @deleted:{false}`)
                .then((result) => {
                    this.offers = result.data.documents;
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async getUserOffers(uid){
            return new Promise((resolve, reject) => {
                this.REDIS_searchFor("user-offer", "", "", "", "", `${this.computedSearch} @userId:{${uid}}  @status:{unclaimed}`)
                .then((result) => {
                    this.useroffers = result.data.documents;
                    for (const offer in this.useroffers){
                        this.REDIS_read("offer", this.useroffers[offer].offerId).then((result) => {
                            //console.log(result.data.offerTitle + ' ' + result.data.deleted)
                            if(!result.data.deleted){
                                // console.log("deleted offer: "+ offer)
                                this.availableOffers.push(this.useroffers[offer])
                            }
                        })
                    }
                    //console.log(JSON.stringify(this.useroffers))
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async getFavouriteStores(uid){
            return new Promise((resolve, reject) => {
                this.REDIS_searchFor("stats", "", "", "", "", `@statType:{storeFavourite} @userId:{${uid}} @deleted:{false}`)
                .then((result) => {
                    this.favouritestores = result.data.documents;
                    //filter out favourite stores that have a null orgId
                    this.favouritestores = this.favouritestores.filter(function (el) {
                        return el.orgId != null;
                    });
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async getFavouriteEvents(uid){
            return new Promise((resolve, reject) => {
                // console.log("UserId"+ this.GET_currentUserProfile.entityId)
                // console.log(JSON.stringify(this.storedetails.entityId))
                this.REDIS_searchFor("stats", "", "", "", "", `@statType:{eventFavourite} @userId:{${uid}} @deleted:{false}`)
                .then((result) => {
                    this.favouriteevents = result.data.documents;
                    //filter out favourite events that have a null orgId
                    this.favouriteevents = this.favouriteevents.filter(function (el) {
                      return el.eventId != null;
                    });
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        isSwanseaResident(){
            if(this.GET_currentUserProfile){
                const regex = /^(SA1 |SA2 |SA3 |SA4 |SA5 |SA6 |SA7 |SA8 |SA18|SA99)/;
                return regex.test(this.GET_currentUserProfile.postcode);
            }else if(localStorage.userToken){
                return true;
            }else{
                return false;
            }
        },
        getUser(){
            if(this.GET_currentUserProfile != null){
                this.userFirstName = this.GET_currentUserProfile.firstName;
                this.userEntityId = this.GET_currentUserProfile.entityId;
            }else if(localStorage.userToken){
                // console.log("No user profile found, using local storage")
                //console.log(localStorage.userToken)
                this.userFirstName = this.MIX_tokenToUser(localStorage.userToken).given_name;
            }else{
                this.userFirstName = "Guest";
            }
        }
    },
    watch:{
        search:{
            handler: function(){
                this.initialise();
            },
            deep: true
        }
    },
    created() {
        //take the user to the standard home page if they are not logged in
        if(!this.MIX_isLoggedIn()){
            this.$router.push("/");
        }
        this.initialise();
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}

.favbarhome{
    margin-top: -2em !important;
}

@media screen and (max-height: 900px) {
    .favbarhome{
    margin-top: 0em !important;
}

}
</style>
