<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain
                class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <v-row>
                <v-col cols="12" xs="12" sm="6">
                    <div class="title font-weight-black primary--text">{{$t("businessusers")}}</div>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
           <v-row class="lightgrey rounded-of">
            <!-- <v-col cols="12" xs="12" sm="12" class="pa-6"  v-for="(user, index) in users" :key="index">
                <UserCard :datadown="user" v-on:userprofileupdated="initialise()"/>
            </v-col> -->
            <v-col cols="6" xs="6" sm="6" class="">
                    <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="white"
                        style=" max-width: 17em;" />
                </v-col>
            <v-col cols='12'>
                    <v-data-table :items-per-page="pageCount" mobile-breakpoint="0" :items="users" :headers="userHeaders" :search="search" class="roundedtable">
                        <!-- <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Events</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template> -->
                        <!-- <template v-slot:[`item.eventName`]="{ item }">
                            <div>{{ item.eventName }}</div>
                        </template>

                        <template v-slot:[`item.eventDateTime`]="{ item }">
                            <div>{{ MIX_formatDateTime(item.eventDateTime, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY') }}</div>
                        </template>

                        <template v-slot:[`item.eventOpenClose`]="{ item }">
                            <div>{{ item.eventOpenClose }}</div>
                        </template>

                        <template v-slot:[`item.eventDetails`]="{ item }">
                            <div>{{ item.eventDetails.substring(0, 50) + "..." }}</div>
                        </template> -->
                        <template v-slot:[`item.firstName`]="{ item }">
                          {{item.firstName + ' ' + item.lastName}}
                        </template>

                        <template v-slot:[`item.orgId`]="{ item }">
                          <OrgNameFromID :orgId="item.orgId"/>
                        </template>
                        
                        <template v-slot:[`item.level`]="{ item }">
                          {{ levelformatted(item.level) }}
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <div><AdminProfileDialog :userProfile="item" v-on:edited="initialise" /></div>
                        </template>

                        <template v-slot:[`item.delete`]="{ item }">
                            <div><DeleteDialog collection="user" :entityId="item.entityId" :itemName="item.firstName" v-on:deleted="initialise()" /></div>
                        </template>

                    </v-data-table>
                </v-col>
           </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "AdminUsersView",
    data: () => ({
        loading: false,
        users: [],
        search: "",
        pageCount: 10,
        userHeaders: [
            { text: "Name", value: "firstName" },
            { text: "Email", value: "email" },
            { text: "Postcode", value: "postcode" },
            { text: "Business", value: "orgId" },
            { text: "Status", value: "status" },
            { text: "Level", value: "level" },
            { text: "Edit", value: "edit" },
            { text: "Delete", value: "delete" },],
    }),
    components: {
        // UserCard: () => import("@/components/card/UserCard.vue"),
        AdminProfileDialog: () => import("@/components/admin/AdminProfileDialog.vue"),
        DeleteDialog: () => import("@/components/util/DeleteDialog.vue"),
        OrgNameFromID: () => import("@/components/util/OrgNameFromID.vue"),
    },
    computed:{
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
    },
    methods:{
        async initialise() {
            this.loading = true;
            //let contactresult = await this.REDIS_getAll("contact");
            //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
            let userresult = await this.REDIS_searchFor("user", "", "", "createdDateTime", "desc", "@level:{shopadmin | superadmin} @deleted:{false}");
            this.users = userresult.data.documents;
            this.loading = false;
            //console.log(JSON.stringify(this.stores, null, 2))
            //console.log(this.stores[1].name)
        },
        levelformatted(item){
            if(item === "user"){
                return "User";
            }else if(item === "superadmin"){
                return "Swansea Bid Admin";
            }else if(item === "shopadmin"){
                return "Business Admin";
            }else{
                return item
            }
    },
    },
    created(){
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}
.paragraphtext{
    line-height: 1.4rem !important;
}
</style>
