import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    showDrawer: false, 
    showAdminDrawer: false,
    showFilterDrawer: false,
    authenticated: false,
    isAdmin: false,
    alertBox: { color: "black", timeout: 2000, message: "", show: false },
    currentUserAuth: null,
    currentUserProfile: null,
    hideInstallDialog: false
  },
  getters: {
    GET_showFilterDrawer: state => state.showFilterDrawer,
    GET_showDrawer: state => state.showDrawer,
    GET_authenticated: state => state.authenticated,
    GET_isAdmin: state => state.isAdmin,
    GET_showAdminDrawer: state => state.showAdminDrawer,
    GET_alertBox: state => state.alertBox,
    GET_currentUserAuth: state => state.currentUserAuth,
    GET_currentUserProfile: state => state.currentUserProfile,
    GET_hideInstallDialog: state => state.hideInstallDialog
  },
  mutations: {
    MUT_showFilterDrawer: (state, payload) => state.showFilterDrawer = payload,
    MUT_showDrawer: (state, payload) => state.showDrawer = payload,
    MUT_authenticated: (state, payload) => state.authenticated = payload,
    MUT_isAdmin: (state, payload) => state.isAdmin = payload,
    MUT_showAdminDrawer: (state, payload) => state.showAdminDrawer = payload,
    MUT_alertBox: (state, payload) => state.alertBox = payload,
    MUT_currentUserAuth: (state, payload) => state.currentUserAuth = payload,
    MUT_currentUserProfile: (state, payload) => state.currentUserProfile = payload,
    MUT_hideInstallDialog: (state, payload) => state.hideInstallDialog = payload
  },
  actions: {
    ACT_showFilterDrawer: ({commit}, payload) => commit('MUT_showFilterDrawer', payload),
    ACT_showDrawer: ({commit}, payload) => commit('MUT_showDrawer', payload),
    ACT_authenticated: ({commit}, payload) => commit('MUT_authenticated', payload),
    ACT_isAdmin: ({commit}, payload) => commit('MUT_isAdmin', payload),
    ACT_showAdminDrawer: ({commit}, payload) => commit('MUT_showAdminDrawer', payload),
    ACT_alertBox: ({commit}, payload) => commit('MUT_alertBox', payload),
    ACT_currentUserAuth: ({commit}, payload) => commit('MUT_currentUserAuth', payload),
    ACT_currentUserProfile: ({commit}, payload) => commit('MUT_currentUserProfile', payload),
    ACT_hideInstallDialog: ({commit}, payload) => commit('MUT_hideInstallDialog', payload)
  },
  modules: {
  }
})
