<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-row class="px-10">
            <v-col cols="6">
                <div class="title font-weight-black primary--text pl-4">{{ $t("dashboard") }}</div>
            </v-col>
        </v-row>

        <v-row cols="12" class="colpadding" v-if="isAdmin()">
            <v-col cols="12" xs="12" md="6">
                <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
                <div class="title font-weight-black black--text pl-4">{{ $t('trafficoverthelast24hours') }}</div>
                  <div class="subtitle black--text pl-4 pb-7">{{ $t('trafficoverthelast24hoursdesc') }}</div>
                <LineGraph />
                </v-card>
            </v-col>
          <v-col cols="12" xs="12" md="6" v-if="isAdmin()">
            <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
              <div class="title font-weight-black black--text pl-4 pb-3">{{ $t("businesssectors") }}</div>
              <PredictedVotePieChart seriesName="Business Sector:" :datadown="businessSectorData" />
            </v-card>
          </v-col>
        </v-row>

        <v-row cols="12" class="colpadding" v-if="isShopAdmin()">
            <v-col>
                <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
                    <div class="title font-weight-black black--text pl-4 pb-7">{{ $t("monthlytraffic") }}</div>
                    <LineGraphBA />
                </v-card>
            </v-col>
        </v-row>

        <v-row cols="12" class="colpadding">
            <v-col cols="12" xs="12" md="6" v-if="isAdmin()">
                <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
                <div class="title font-weight-black black--text pl-4 pb-3">{{ $t("predictedvotes") }}</div>
                <PredictedVotePieChart seriesName="Predicted Votes" :datadown="votedata" />
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6" v-if="isAdmin()">
                <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
                <div class="title font-weight-black black--text pl-4 pb-7">{{ $t("topstats") }}</div>
<!--                <HorizontalBarGraph v-if="disabled" />-->
                    <div class="title pl-4 pb-7">{{ $t("totalusers") }}: <b>{{ totalUsers }}</b></div>
                  <div class="title pl-4 pb-7">{{ $t("registeredbusinessadmins") }}: <b>{{ totalAdminUsers }}</b></div>
                  <div class="title pl-4 pb-7">{{ $t("totalevents") }}: <b>{{ totalEvents }}</b></div>
                  <div class="title pl-4 pb-7">{{ $t("totaloffers") }}: <b>{{ totalOffers }}</b></div>
                </v-card>
            </v-col>
        </v-row>

        <v-row cols="12" class="colpadding" v-if="isAdmin()">
          <v-col cols="12" xs="12" md="6">
            <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
              <div class="title font-weight-black black--text pl-4">{{ $t('trafficoverthelastweek') }}</div>
              <div class="subtitle black--text pl-4 pb-7">{{ $t('trafficoverthelast24hoursdesc') }}</div>
              <LineGraphDaily />
              <!--                <div class="title font-weight-black black&#45;&#45;text pl-4 pb-7">Not enough data</div>-->
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" md="6">
            <v-card elevation="0" class="lightgrey rounded-of full-height pa-5">
              <div class="title font-weight-black black--text pl-4">{{ $t('trafficoverthelastmonth') }}</div>
              <div class="subtitle black--text pl-4 pb-7">{{ $t('trafficoverthelast24hoursdesc') }}</div>
              <LineGraphMonthly />
              <!--                <div class="title font-weight-black black&#45;&#45;text pl-4 pb-7">Not enough data</div>-->
            </v-card>
          </v-col>
        </v-row>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { customers } from '@/sampleData.js';
import LineGraphDaily from "@/components/graph/LineGraphDaily.vue";
import LineGraphMonthly from "@/components/graph/LineGraphMonthly.vue";
export default {
    name: "AdminDashboardView",
    data: () => ({
        search: "",
        disabled: false,
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        items: [
            { name: "Item 1", v: "value 1" },
            { name: "Item 2", v: "value 2" },
            { name: "Item 3", v: "value 3" },
            { name: "Item 4", v: "value 4" },
        ],
        votedata: [
                        { "value": 0, "name": 'Need to Contact' },
                        { "value": 0, "name": 'Yes' },
                        { "value": 0, "name": 'Potential Yes' },
                        { "value": 0, "name": 'Maybe' },
                        { "value": 0, "name": 'Potential No' },
                        { "value": 0, "name": 'No' },
                        { "value": 0, "name": 'Undecided'},
                        { "value": 0, "name": 'Voluntary member (no vote)'}
                    ],
      businessSectorData: [
        { "value": 0, "name": 'Hospitality' },
        { "value": 0, "name": 'Entertainment' },
        { "value": 0, "name": 'Retail' },
        { "value": 0, "name": 'Public' },
        { "value": 0, "name": 'Hotel' },
        { "value": 0, "name": 'Finance' },
        { "value": 0, "name": 'Legal'},
        { "value": 0, "name": 'Education'},
        { "value": 0, "name": 'Professional'}
      ],

      //totals
      totalUsers: 0,
      totalAdminUsers: 0,
      totalEvents: 0,
      totalOffers: 0,
    }),
    components: {
      LineGraphMonthly,
      LineGraphDaily,
        LineGraph: () => import("@/components/graph/LineGraph.vue"),
        LineGraphBA: () => import("@/components/graph/LineGraphBA.vue"),
        // HorizontalBarGraph: () => import("@/components/graph/HorizontalBarGraph.vue"),
        PredictedVotePieChart: () => import("@/components/graph/PredictedVotePieChart.vue"),
    },
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
        limitedCustomers(limit) {
            let customerData = []
            customerData = customers.slice(0, limit)
            //console.log(customerData)
            return customerData
        },
        isAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'superadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        isShopAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'shopadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        async getVotes(){ //TODO - this is a temporary solution to get the data for the pie chart. Need to find a better way to do this.
            let totalneedtocontact = await this.REDIS_searchFor("org", "", "", "", "", '@deleted:{false} @predictedVote:{NeedtoContact}');
            this.votedata[0].value = totalneedtocontact.data.total;
            let totalyes = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Yes}");
            this.votedata[2].value = totalyes.data.total;
            let totalpotyes = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Potential Yes}");
            this.votedata[3].value = totalpotyes.data.total;
            let totalmaybe = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Maybe}");
            this.votedata[4].value = totalmaybe.data.total;
            let totalpotno = await this.REDIS_searchFor("org", "", "", "", "", "@deleted:{false} @predictedVote:{PotentialNo}");
            this.votedata[5].value = totalpotno.data.total;
            let totalno = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{No}");
            this.votedata[6].value = totalno.data.total;
            let totalundecided = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{Undecided}");
            this.votedata[1].value = totalundecided.data.total;
            let totavoluntary = await this.REDIS_searchFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @predictedVote:{VoluntaryMember}");
            this.votedata[7].value = totavoluntary.data.total;
        },
      async getTotals(){
        let totalusers = await this.REDIS_searchFor("user", "", "", "", "", "@level:{user} @deleted:{false}");
        this.totalUsers = totalusers.data.total;
        let totaladminusers = await this.REDIS_searchFor("user", "", "", "", "", "@deleted:{false} @level:{superadmin | shopadmin}");
        this.totalAdminUsers = totaladminusers.data.total;
        let totalevents = await this.REDIS_searchFor("event", "", "", "", "", "@deleted:{false}");
        this.totalEvents = totalevents.data.total;
        let totaloffers = await this.REDIS_searchFor("offer", "", "", "", "", "@deleted:{false}");
        this.totalOffers = totaloffers.data.total;
      },
      async getBusinessStats(){
          let hospitalityRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Hospitality")
          this.businessSectorData[0].value = hospitalityRes.total;
          let entertainmentRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Entertainment")
          this.businessSectorData[1].value = entertainmentRes.total;
          let retailRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Retail")
          this.businessSectorData[2].value = retailRes.total;
          let publicRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Public")
          this.businessSectorData[3].value = publicRes.total;
          let hotelRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Hotel")
          this.businessSectorData[4].value = hotelRes.total;
          let financeRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Finance")
          this.businessSectorData[5].value = financeRes.total;
          let legalRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Legal")
          this.businessSectorData[6].value = legalRes.total;
          let educationRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Education")
          this.businessSectorData[7].value = educationRes.total;
          let proffesionalRes = await this.REDIS_countFor("org", "", "", "createdDateTime", "asc", "@deleted:{false} @type:Professional")
          this.businessSectorData[8].value = proffesionalRes.total;

          // const totalBusiness = hospitalityRes.total + entertainmentRes.total + retailRes.total + publicRes.total + hotelRes.total + financeRes.total + legalRes.total + educationRes.total + proffesionalRes.total;
          // this.businessSectorData[0].value = Math.floor((hospitalityRes.total / totalBusiness) * 100);
          // this.businessSectorData[1].value = Math.floor((entertainmentRes.total / totalBusiness) * 100);
          // this.businessSectorData[2].value = Math.floor((retailRes.total / totalBusiness) * 100);
          // this.businessSectorData[3].value = Math.floor((publicRes.total / totalBusiness) * 100);
          // this.businessSectorData[4].value = Math.floor((hotelRes.total / totalBusiness) * 100);
          // this.businessSectorData[5].value = Math.floor((financeRes.total / totalBusiness) * 100);
          // this.businessSectorData[6].value = Math.floor((legalRes.total / totalBusiness) * 100);
          // this.businessSectorData[7].value = Math.floor((educationRes.total / totalBusiness) * 100);
          // this.businessSectorData[8].value = Math.floor((proffesionalRes.total / totalBusiness) * 100);
      }
    },
    created() {
        this.getVotes();
        this.getTotals();
        this.getBusinessStats();
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}

.addpadmobile {
    padding-top: 1.7em;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
}

@media only screen and (min-width: 768px) {
    .addpadmobile {
        padding-top: 1em;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.colpadding{
    padding-left: 40px;
    padding-right: 40px;
}

@media all and (min-width:0px) and (max-width: 480px) {
    .colpadding{
    padding-left: 30px;
    padding-right: 30px;
}
}
</style>
