<template>
    <div class="primary full-viewpoint-height">
        <v-container class="primary d-flex flex-row align-end justify-center">
            <v-row class="dense" style="min-height: 100vh;">
                <v-col cols="12">
                    <v-img src="@/assets/Big-Heart-Logo.png" class="loginicon" contain height="250px" />
                </v-col>
                <v-col cols="12" xs="12" sm="12" class="nopadding d-flex justify-center">
                    <div class=" py-5 white rounded-of-top-xl">

                        <div class="primary--text text-center font-weight-black display-1">{{ $t("signup") }}</div>

                        <v-row class="px-1 pt-2" dense>
                            <v-col cols="12" class="pb-6">
                                <div class="text-center title">{{ $t("registermessage1") }}</div>
                            </v-col>
                            <v-window v-model="step" class="full-width">
                                <v-window-item :value="1">
                                    <v-row class="px-6">
                                        <v-col cols="12" class="pb-3">
                                            <TextField fieldStyle="min-width: 15vw;" label="First Name" background="lightgrey" v-model.trim="user.firstName"
                                                :validate="errors.firstName" />
                                        </v-col>
                                        <v-col cols="12" class="pb-3">
                                            <TextField fieldStyle="min-width: 15vw;" label="Last Name" background="lightgrey" v-model.trim="user.lastName"
                                                :validate="errors.lastName" />
                                        </v-col>
                                        <v-col cols="12" class="pb-3">
                                            <TextField fieldStyle="min-width: 15vw;" label="Email" background="lightgrey" v-model="user.email"
                                                :validate="errors.email" />
                                        </v-col>
                                        <v-col cols="12" class="pb-3">
                                            <TextField fieldStyle="min-width: 15vw;" @input="user.postcode = user.postcode.toUpperCase()" label="Postcode" background="lightgrey" v-model="user.postcode"
                                                :validate="errors.postcode" />
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-row dense>
                                        <v-col cols="12" class="text-body-1">
                                            <v-expansion-panels v-model="showPasswordRequirements"
                                                class="rounded-of elevation-0" flat accordion>
                                                <v-expansion-panel dense>
                                                    <v-expansion-panel-header disable-icon-rotate
                                                        class="grey lighten-2 font-weight-bold text-body-2 ">
                                                        Please enter a Password
                                                        <template v-slot:actions>
                                                            <v-icon color="primary">icons8-info</v-icon>
                                                        </template>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content class="grey lighten-2">
                                                        <ul class="text-body-2">
                                                            <li>Minimum length of 8 characters</li>
                                                            <li>At least one uppercase letter</li>
                                                            <li>At least one lowercase letter</li>
                                                            <li>At least one numerical digit</li>
                                                            <li>At least one special character</li>
                                                        </ul>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                        <v-col cols="12">
                                            <PasswordField class="px-6" background="lightgrey" label="Password" v-model="password"
                                                :validate="errors.password" />
                                        </v-col>
                                        <v-col cols="12">
                                            <PasswordField class="px-6" background="lightgrey" label="Password Confirmation"
                                                v-model="passwordConfirm" :validate="errors.passwordConfirm" />
                                        </v-col>
                                        <v-col cols="12">
                                        </v-col>
                                        <v-col cols="12">
                                            <CheckboxField v-model="termsAgreed">
                                                I agree and have read the <a @click="openTermsAndConditions">Terms & Conditions</a>
                                            </CheckboxField>
                                        </v-col>
                                    </v-row>
                                    <!-- <pre>{{ user }}</pre> -->
                                </v-window-item>
                            </v-window>
                        </v-row>

                        <v-row>
                            <v-col cols="6" class="px-6 pb-3">
                                <v-btn x-large @click="backStep(step)" large depressed block
                                    class="lightgrey black--text  rounded-of">{{ $t("back") }}</v-btn>
                            </v-col>
                            <v-col cols="6" class="px-6 pb-3">
                                <v-btn x-large @click="verifyStep(step)" large depressed block
                                    class="primary white--text rounded-of">{{ $t("next") }}</v-btn>
                            </v-col>
                        </v-row>

                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { bannedPasswords } from "@/bannedPasswords.js";
// import DropdownAdvanced from "@/components/form/DropdownAdvanced.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "RegisterView",
    // components: { DropdownAdvanced },
    data: () => ({
        step: 1,
        showPasswordRequirements: -1,
        user: {
            firstName: "",
            lastName: "",
            email: "",
            postcode: "",
            deleted: false,
        },
        passwords: [],
        password: "",
        passwordConfirm: "",
        termsAgreed: false,
        error: "",
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            orgType: "",
            jobRole: "",
            password: "",
            passwordConfirm: "",
            termsAgreed: "",
            postcode: "",
        },
        rules: {},
        orgTypes: [],
        jobRoles: [],
    }),
    computed: {
        ...mapGetters({
            GET_currentUserAuth: "GET_currentUserAuth",
        }),
    },
    components: {

    },
    methods: {
        ...mapActions({
            //ACT_showTermsConditions: 'ACT_showTermsConditions',
            //ACT_currentUserProfile: "ACT_currentUserProfile",
        }),
        openTermsAndConditions(){
            window.open('/BIG-Heart-TandC.pdf', '_blank');
        },
        backStep(step) {
            if (step === 1) {
                this.MIX_go("/login");
            } else {
                this.step--;
            }
        },
        checkPasswordComplexity(password) {
            if (password.length < 8) {
                return "Password must be at least 8 characters long.";
            } else if (!/[a-z]/.test(password)) {
                return "Password must contain at least one lowercase letter.";
            } else if (!/[A-Z]/.test(password)) {
                return "Password must contain at least one uppercase letter.";
            } else if (!/[0-9]/.test(password)) {
                return "Password must contain at least one numerical digit.";
            } else if (!/[!@#$%^&*()]/.test(password)) {
                return "Password must contain at least one special character.";
            } else {
                return "Password is valid.";
            }
        },
        emailValidation(email) {
            let re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        postcodeValidation(postcode) {
            let re = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i;
            return re.test(postcode);
        },
        async verifyStep(step) {
            //console.log("verifyStep", step);
            //add the space if it's a swansea postcode and is missing
            // Validate Steps
            if (step === 1) {
                //console.log("on step 1")
                // Validate Step 1
                if(this.user.postcode.length === 6){
                  this.user.postcode = this.user.postcode.slice(0,3) + " " + this.user.postcode.slice(3,6)
                }else if(this.user.postcode.length === 7 && this.user.postcode.slice(3,4) !== " "){
                  this.user.postcode = this.user.postcode.slice(0,4) + " " + this.user.postcode.slice(4,7)
                }
                let errorCount = 0;
                if (this.user.firstName === "") {
                    this.error = "First Name is required";
                    errorCount++;
                }
                if (this.user.lastName === "") {
                    this.error = "Last Name is required";
                    errorCount++;
                }
                if (this.user.email === "") {
                    this.error = "Email is required";
                    errorCount++;
                }
                if (!this.emailValidation(this.user.email)) {
                    this.error = "Enter a valid email address";
                    errorCount++;
                }
                if (this.user.orgType === "") {
                    this.error = "Organisation Type is required";
                    errorCount++;
                }
                if (this.user.jobRole === "") {
                    this.error = "Organisation Role is required";
                    errorCount++;
                }
                if (this.user.postcode === "") {
                    this.error = "Postcode is required";
                    errorCount++;
                }
                if (!this.postcodeValidation(this.user.postcode)) {
                    this.error = "Enter a valid postcode";
                    errorCount++;
                }
                if (errorCount === 0) {
                    this.step++;
                } else {
                    this.MIX_alertBox({ show: true, message: this.error, color: "error", timeout: "4000" });
                }
            } else if (step === 2) {
                // Validate Step 2
                let errorCount = 0;
                switch (true) {
                    // Missing Password
                    case this.password === "":
                        this.errors.password = "Password is required";
                        errorCount++;
                        break;
                    // Missing Password Confirmation
                    case this.passwordConfirm === "":
                        this.errors.passwordConfirm = "Password Confirmation is required";
                        errorCount++;
                        break;
                    // Passwords do not match
                    case this.password !== this.passwordConfirm:
                        this.errors.password = "Passwords do not match";
                        this.errors.passwordConfirm = "Passwords do not match";
                        errorCount++;
                        break;
                    // Password is not in banned list
                    case bannedPasswords.includes(this.password):
                        this.errors.password = "Password is too common";
                        this.errors.passwordConfirm = "Password is too common";
                        errorCount++;
                        break;
                    // Password meets complexity requirements
                    case this.checkPasswordComplexity(this.password) !== "Password is valid.":
                        this.showPasswordRequirements = 0;
                        this.errors.password = this.checkPasswordComplexity(this.password);
                        errorCount++;
                        break;
                    // Defaults
                    default:
                        this.errors.password = "";
                        this.errors.passwordConfirm = "";
                        break;
                }
                if(!this.termsAgreed){
                  this.errors.password = "You must agree to the Terms & Conditions";
                  errorCount++;
                }
                if (errorCount === 0) {
                    const fullName = this.user.firstName + " " + this.user.lastName;
                    let firebaseAuthResult = await this.FIREBASE_register(fullName, this.user.email, this.password);
                    //console.log('firebaseAuthResult = ', firebaseAuthResult)
                    if (firebaseAuthResult.success) {
                        // console.log('this.GET_currentUserAuth = ', this.GET_currentUserAuth)
                        this.user.level = "user";
                        this.user.status = "Approved";
                        this.user.createdUserId = this.GET_currentUserAuth.uid;
                        this.user.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                        this.user.modifiedUserId = this.GET_currentUserAuth.uid;
                        this.user.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
                        // console.log("user = ", JSON.stringify(this.user, null, 2));
                        let createNewUserResult = await this.REDIS_createNewUser(this.user, this.GET_currentUserAuth.uid);
                        // console.log(createNewUserResult);
                        // this.MIX_go("/home");
                        if (createNewUserResult.success) {
                            this.ACT_currentUserProfile(this.user)
                            // this.user = { ...this.$schema.user }
                            this.MIX_go("/home");
                        }
                    } else {
                        if (firebaseAuthResult.error.toString().search("auth/email-already-in-use") !== -1) {
                            //console.log('Email Already in Use')
                            this.MIX_alertBox({ show: true, message: 'Email Already in Use', color: "error", timeout: "4000" });
                        } else {
                            console.log('Unknown Authentication Error')
                        }
                    }
                } else {
                    this.MIX_alertBox({ show: true, message: this.errors.password, color: "error", timeout: "4000" });
                }
            }
        },
    },
    // async created() {
    //     this.user = this.$schema.user;
    //     // Lookups
    //     // TODO - Replace with unauthenticated lookup as lookup is public
    //     let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{orgType|jobRole}");
    //     this.orgTypes = lookupResult.data.documents.filter((item) => item.type === "orgType");
    //     this.jobRoles = lookupResult.data.documents.filter((item) => item.type === "jobRole");
    //     this.passwords = bannedPasswords;
    // },
};
</script>

<style scoped>
.nopadding {
    padding: 0 !important;
}

.biggerbutton {
    height: 100px !important;
}

.loginicon {
    height: 250px;
}

@media screen and (max-height: 900px) {
    .loginicon {
        height: 150px;
    }
}

.bottom {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100% !important;
    position: fixed !important;
    height: 100px !important;
}</style>