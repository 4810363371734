<template>
 <div class="pt-2">
  <v-progress-linear indeterminate size="24" color="primary" v-if="loading"></v-progress-linear>
  <v-select
    :items="items"
    item-text="label"
    item-value="value"
    v-model="value"
    :key="value"
    v-on:change="voteChanged"
    v-if="!loading"
    :background-color="dropdownColor"
    :color="textColor"
    solo
    class="roundcorners"
    v-bind:disabled="userLevel !== 'superadmin'"
  ></v-select>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
  export default {
    data: () => ({
      items: [
        {label:"Pending Approval", value: false},
        {label:"Approved", value: true},
      ],
      value: "",
      form:{
       approvalStatus: false
      },
      loading: false,
      dropdownColor: "grey",
      textColor: "white"
    }),
    props: {
      datadown: {
        type: Object,
        default: null
      },
     userLevel: {
        type: String,
        default: 'shopadmin'
      },
    },
    computed:{
      ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
   watch: {
    datadown() {
     this.value = this.datadown.approvalStatus;
     this.changeDropdownColour();
    }
   },
    methods: {
      async voteChanged() {
      this.loading = true;
      this.form.approvalStatus = this.value;
      //console.log(this.datadown.designatedVoterId)
      await this.REDIS_update("event", this.datadown.entityId, this.form);
      // console.log("voteChanged: " + this.value)
      this.changeDropdownColour();
      this.loading = false;
        },
      changeDropdownColour() {
        //console.log("changeDropdownColour: " + this.value)
       if (this.value) {
          this.dropdownColor = "green";
          this.textColor = "white";
        }if (!this.value) {
          this.dropdownColor = "red";
          this.textColor = "white";
        }
      },
    },
    created() {
      this.value = this.datadown.approvalStatus;
      this.changeDropdownColour();
    }
  }
</script>

<style scoped>
.roundcorners {
  border-radius: 15px;
}

</style>