<template>
    <div>
    <v-bottom-navigation height="70" class="primary fixed rounded-of-top-mid" app color="primary" horizontal>
        <div class="d-flex align-center justify-center">
            <v-btn @click="updateshowAdminDrawer()" class="primary text-body-2 font-weight-bold white--text rounded-of" block x-large depressed
                    ><div class="pb-2 full-width white--text text-center headline">{{ $t("menu") }}</div>
                    <v-icon class="pb-2 white--text" x-large>icons8-menu-rounded</v-icon>
                    </v-btn>
        </div>
    </v-bottom-navigation>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "AdminBottomNav",
    components: {
    },
    computed: {
        ...mapGetters({
            GET_showAdminDrawer: "GET_showAdminDrawer",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showAdminDrawer: "ACT_showAdminDrawer",
        }),
        updateshowAdminDrawer() {
            //console.log("Click!")
            this.ACT_showAdminDrawer(!this.GET_showAdminDrawer);
        },
        changeLanguage(language) {
            this.$i18n.locale = language;
        }
    }
    
}
</script>

<style scoped>

</style>
