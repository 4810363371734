<template>
  <div>
    <v-progress-linear indeterminate size="24" color="primary" v-if="loading"></v-progress-linear>
    <v-chart v-if="!loading" class="chart" :option="option" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([GridComponent, CanvasRenderer, LineChart,]);

export default defineComponent({
  name: "LineGraphDaily",
  data: () => ({
    loading: false,
  }),
  components: {
    VChart,
  },
  props: {
    seriesdata: {
      type: Array,
      default: () => [],
    },
    xaxisdata: {
      type: Array,
      default: () => [],
    },
  },
  provide: {
    [THEME_KEY]: "light",
  },
  methods:{
    //this grabs the stats from redis and pushes it to the chart
    async getChartData(){
      this.loading = true
      //convert a week to days (7)
      let days = 7;
      let i = 1;
      while(i < 8){ //loop through for 7 days
        //add 7 days each time for the search query
        days = i;
        console.log(days)
        //Redis query: searches between the current day and 7 days ago, then a week before that etc.
        let searchQuery = `@statType:{userLogin} @createdDateTime:[${this.$dayjs().subtract(days, 'days').unix()} ${this.$dayjs().subtract(days-1, 'days').unix()}]`;
        await this.REDIS_searchFor('stats', "", "", "", "", searchQuery).then((res) => {
          // console.log(res)
          // const loginStats = res.data.documents;
          //push the grabbed data to the chart
          this.option.xAxis.data.push(this.MIX_formatDateTime(this.$dayjs().subtract(days, 'days'), 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY'))
          this.option.series[0].data.push(res.data.total)
        });
        i++;
      }
      //as the data is coming in backwards, reverse the data to show the most recent first
      this.option.xAxis.data.reverse()
      this.option.series[0].data.reverse()
      this.loading = false
    }
  },
  created(){
    this.getChartData();
  },
  setup() { //vm
    const option = ref({
      xAxis: {
        type: 'category',
        data: [],
        nameTextStyle: {
          color: "#CC0200",
        }
      },
      yAxis: {
        type: 'value'

      },
      series: [
        {
          data: [],
          type: 'line',
          smooth: true
        }
      ],
      color: [
        "#CC0200"
      ]
    });

    return { option };
  },
});
</script>

<style scoped>
.chart {
  width: 100% !important;
  height: 300px !important;
  margin-bottom: -40px;
  margin-top: -50px;
  /* height: 100vh; */
}
</style>
