const stores = [
    { entityid: "1001", name: "Turtle Bay", type: "restaurant", img: "Turtle-Bay.png", status: "Open" },
    { entityid: "1002", name: "Primark", type: "clothing", img: "Primark.png", status: "Closed" },
    { entityid: "1003", name: "Wetherspoons", type: "bar", img: "Bar.png", status: "Open" },
    { entityid: "1001", name: "Turtle Bay", type: "restaurant", img: "Turtle-Bay.png", status: "Open" },
    { entityid: "1002", name: "Primark", type: "clothing", img: "Primark.png", status: "Closed" },
    { entityid: "1003", name: "Wetherspoons", type: "bar", img: "Bar.png", status: "Open" },
    { entityid: "1001", name: "Turtle Bay", type: "restaurant", img: "Turtle-Bay.png", status: "Open" },
    { entityid: "1002", name: "Primark", type: "clothing", img: "Primark.png", status: "Closed" },
    { entityid: "1003", name: "Wetherspoons", type: "bar", img: "Bar.png", status: "Open" },
]

const offers = [
    { offer: "10% OFF", offerType: "Discount", storeId: "1001", img: "Turtle-Bay.png" },
    { offer: "30% OFF", offerType: "Discount", storeId: "1003", img: "Bar.png" },
    { offer: "5% OFF", offerType: "Discount", storeId: "1002", img: "Primark.png" },
    { offer: "10% OFF", offerType: "Discount", storeId: "1001", img: "Turtle-Bay.png" },
    { offer: "30% OFF", offerType: "Discount", storeId: "1003", img: "Bar.png" },
    { offer: "5% OFF", offerType: "Discount", storeId: "1002", img: "Primark.png" },
]

const events = [
    { entityid: "2001", name: "Vintage car show", img: "Car-Show.png", date: "Saturday 3rd November" },
    { entityid: "2002", name: "Superhero Parade", img: "Superhero-Parade.png", date: "Saturday 12th November" },
    { entityid: "2003", name: "Christmas Market", img: "Christmas-Market.png", date: "Friday 24th November" },
    { entityid: "2001", name: "Vintage car show", img: "Car-Show.png", date: "Saturday 3rd November" },
    { entityid: "2002", name: "Superhero Parade", img: "Superhero-Parade.png", date: "Saturday 12th November" },
    { entityid: "2003", name: "Christmas Market", img: "Christmas-Market.png", date: "Friday 24th November" },
    { entityid: "2001", name: "Vintage car show", img: "Car-Show.png", date: "Saturday 3rd November" },
    { entityid: "2002", name: "Superhero Parade", img: "Superhero-Parade.png", date: "Saturday 12th November" },
    { entityid: "2003", name: "Christmas Market", img: "Christmas-Market.png", date: "Friday 24th November" },
]

const customers = [
    {
        name: "Elliot Jones",
        email: "Elliot@yahoo.co.uk",
        img: "Bonsoir-Elliot.png",
        offersActivated: 39,
    },
    {
        name: "Olivia Pedersen",
        email: "OPedersen@gmail.com",
        img: "Olivia.png",
        offersActivated: 24,
    },
    {
        name: "Serena Jones",
        email: "SeJones@hotmail.com",
        img: "Serena.png",
        offersActivated: 20,
    },
    {
        name: "Josh Evans",
        email: "joshevans12@gmail.com",
        img: "Josh.png",
        offersActivated: 40,
    },
    {
        name: "Lily Jones",
        email: "LJones15@outlook.com",
        img: "Lily.png",
        offersActivated: 10,
    }
]

const contacts = [
    {name: "Waste Disposal", img: "WasteDisposal.png"},
    {name: "Plumbing", img: "Plumbing.png"},
    {name: "Biohazard", img: "Biohazard.png"},
    {name: "City Cleansing", img: "CityCleaning.png"}
]

module.exports = { stores, offers, events, customers, contacts }