<template>
    <v-text-field
        :style="fieldStyle"
        :label="label"
        v-model="innerValue"
        :error-messages="error"
        filled
        rounded
        :type="type"
        class="rounded-lg"
        :background-color="background"
        hide-details="auto"
        clearable
        @keydown.enter="submit()"
        @click:clear="clear()" 
        :dense="makedense"
        :disabled="disabled"
        >
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        error: {
            type: String,
            default: "",
        },
        background:{
            type: String,
            default: "grey lighten-4"
        },
        makedense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldStyle: {
          type: String,
          default: ""
        }
    },
    data() {
        return {
            innerValue: "",
        };
    },
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = "";
            }, 1);
        },
        submit() {
            this.$emit("submit");
        },
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>
