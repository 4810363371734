<template>
    <div>
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-container>
            <v-row class="dd-flex align-center">
                <v-col cols="6" class="">
                    <div class="title pl-4">{{ $t("defibs") }}</div>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                    <v-btn href="tel:999" class="primary rounded-of mr-3" depressed>
                        <div class="title pr-4 white--text removetexttransform">Call 999</div>
                        <v-icon class="white--text">icons8-phone</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-row class="lightgrey rounded-of mx-3">
                        <v-col cols="12" class="">
                            <div class="subtitle-3"><b class="subtitle-4">If you believe someone is having a cardiac arrest CALL 999 immediately</b><br><br>Heartbeat Trust UK and City & County of Swansea have partnered to develop a network of 300 defibrillators around the city.<br><br>More than 6,000 people will have a sudden cardiac arrest in Wales each year, with around 80% happening in the home. So it's essential to know where your nearest defibrillator is and how to perform CPR.</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pt-10">
                    <v-row class="">
                        <v-col cols="12" sm="6" class="pt-0">
                            <v-img :src="MIX_getImgUrl('DefibAsset4.png')" contain max-height="90px"/>
                            <!-- <v-img :src="MIX_getImgUrl('DefibAsset3.png')" contain max-height="50px"/> -->
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0">
                            <v-img :src="MIX_getImgUrl('DefibAsset5.png')" contain max-height="90px"/>
                            <!-- <v-img :src="MIX_getImgUrl('DefibAsset3.png')" contain max-height="50px"/> -->
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pt-6">
                    <div class="title pl-4">Useful Links</div>
                    <carousel height="auto" :paginationPadding=4 :perPage="1" :perPageCustom="[[768, 1], [1024, 2]]"
                        paginationActiveColor="#1eba4d">
                        <slide v-for="(defibcard, index) in defibcards" :key="index">
                            <DefibExtCard :datadown="defibcard" claimbutton>
                                <template v-slot:toptitle>Test</template>
                                <template v-slot:bottomtitle>Test</template>
                            </DefibExtCard>
                        </slide>
                    </carousel>
                </v-col>

                <v-col cols=12 class="pl-6 py-0">
                    <div class="title pl-4">Videos</div>
                </v-col>
                <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-center">
                    <div class="grey rounded-of p-0"
                        style="overflow: hidden; z-index: 1; height: 144px !important; width: 256px !important;">
                        <iframe width="256" height="144" src="https://www.youtube.com/embed/UFvL7wTFzl0"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-center">
                    <div class="grey rounded-of p-0"
                        style="overflow: hidden; z-index: 1; height: 144px !important; width: 256px !important;">
                        <iframe width="256" height="144" src="https://www.youtube.com/embed/_eXX5JUXeCw"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"></iframe>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-center">
                    <div class="grey rounded-of p-0"
                        style="overflow: hidden; z-index: 1; height: 144px !important; width: 256px !important;">
                        <iframe width="256" height="144" src="https://www.youtube.com/embed/ED9HX0Nx--M"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"></iframe>
                    </div>
                </v-col>

                <v-col cols="12">
                    <div class="title pl-4">Provided By</div>
                </v-col>
                <v-col cols="3">
                    <v-img :src="MIX_getImgUrl('AED LOGOS-02.png')" contain max-height="120px"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "DefibView",
    data: () => ({
        search: "",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        defibcards: [
            { title: "Find Your Nearest Defib", url: "http://www.defibfinder.uk/", img: "DefibSlide2.jpg", type: "link"},
            { title: "Anyone Can Save A Life", url: "https://www.youtube.com/watch?v=dFNLRN_3wa0", img: "VideoThumbnail.png", type: "video"},
            { title: "Getting Involved", url: "http://www.heartbeattrustuk.co.uk", img: "DefibSlide3.jpg", type: "link" },
            { title: "Free CPR & Defib Training", url: "http://www.heartbeattrustuk.co.uk/training/", img: "DefibSlide4.jpg", type: 'link' },
        ]
    }),
    components: {
        // DefibMap: () => import("@/components/DefibMap.vue"),
        DefibExtCard: () => import("@/components/card/DefibExtCard.vue"),
    },
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
    }
};
</script>

<style>.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}</style>
