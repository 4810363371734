<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain
                class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <v-row>
                <v-col cols="6" xs="6" sm="6">
                    <div class="title font-weight-black primary--text">{{ $t("generalcontacts") }}</div>
                </v-col>
                <v-col cols="6" class="d-flex justify-end pr-6" :style="$i18n.locale === 'cy' ? 'margin-left: -4em !important;' : ''"  v-if="GET_currentUserProfile.level === 'superadmin'">
                    <AddContact  v-on:addedContact="initialise()" :key="addContactKey"/>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
           <v-row class="lightgrey rounded-of contactsrow">
            <v-col cols="12" xs="12" sm="4" class="pa-6"  v-for="(contact, index) in contacts" :key="index">
                <ContactCard :datadown="contact" v-on:deleted="initialise()" v-on:edited="initialise()"/>
            </v-col>
           </v-row>
           <v-row class="">
                <v-col cols="12" xs="12" sm="6">
                    <div class="title font-weight-black primary--text">{{ $t("bidmemberoffer") }}</div>
                    <div class="subtitle pr-4 removetexttransform">Contact below for exclusive discounts</div>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
           <v-row class="lightgrey rounded-of contactsrow mb-6">
            <v-col cols="12" xs="12" sm="4" class="pa-6"  v-for="(contact, index) in bidoffers" :key="index">
                <ContactCard :datadown="contact" v-on:deleted="initialise()" v-on:edited="initialise()"/>
            </v-col>
           </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "AdminContactsView",
    data: () => ({
        loading: false,
        contacts: [],
        bidoffers: [],
        search: "",
        addContactKey: 0,
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
    }),
    components: {
        ContactCard: () => import("@/components/card/ContactCard.vue"),
        AddContact: () => import("@/components/AddContact.vue")
    },
    computed:{
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
    },
    methods:{
        async initialise() {
            this.loading = true;
            //let contactresult = await this.REDIS_getAll("contact");
            //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
            let contactresult = await this.REDIS_searchFor("contact", "", "", "createdDateTime", "asc", "@status:{General} @deleted:{false}");
            this.contacts = contactresult.data.documents;
            let bidofferresult = await this.REDIS_searchFor("contact", "", "", "createdDateTime", "asc", "@status:{Bid Member Offer} @deleted:{false}");
            this.bidoffers = bidofferresult.data.documents;
            this.addContactKey++;
            this.loading = false;
            //console.log(JSON.stringify(this.stores, null, 2))
            //console.log(this.stores[1].name)
        }
    },
    created(){
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}
.paragraphtext{
    line-height: 1.4rem !important;
}

/* This adds padding between the add button and contacts block on mobile */
@media all and (min-width:0px) and (max-width: 480px) {
.contactsrow{
    margin-top: 30px !important;
}
}
</style>
